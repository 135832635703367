body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* FIXME: not nice workaround */
.td-start {
  padding-inline-start: 4px !important;
}
/* FIXME: not nice workaround */
.td-end {
  padding-inline-end: 4px !important;
}

@keyframes bounce-building {
  0% {
    transform: translateY(60px) translateX(200px) scale(0.7);
  }
  50% {
    transform: translateY(45px) translateX(200px) scale(0.7);
  }
  100% {
    transform: translateY(60px) translateX(200px) scale(0.7);
  }
}

.bounce-building {
  animation: bounce-building 0.5s ease;
}

@keyframes bounce-house {
  0% {
    transform: translateY(70px) scale(0.7);
  }
  50% {
    transform: translateY(60px)  scale(0.7);
  }
  100% {
    transform: translateY(70px)  scale(0.7);
  }
}

.bounce-house {
  animation: bounce-house 0.5s ease;
}

#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
}
